<template>
  <div class="results-goldies-picks-block">
    <h3 class="fs-40 fw-400 results-goldies-picks-block-title">
      <img src="../../../assets/images/goldies-pick-icon.svg" alt="" class="goldies-pick-title-icon-img">
      Goldie Top Picks
    </h3>
    <div class="rom-count fs-16 results-note-top-picks">Results are displayed based on your best fit</div>
    <div class="goldies-top-picks-block" :class="topPicksBlockClasses()">
      <product-card class="gtp-single-1 goldie-product-block" v-if="topProducts[0]"
        :from="typeOfProducts"
        :productName="topProducts[0].productName"
        :uniqueId="topProducts[0].uniqueId"
        :brandName="topProducts[0].brand.name"
        :fullSize="topProducts[0].fullSize"
        :_id="topProducts[0]._id"
        :isBookmark="topProducts[0].isBookmark"
        :size="topProducts[0].size"
        :notesFromTopPicks="'Fits just right'"
        :productImages="topProducts[0].productImages" @click="openProdDetailPopup(topProducts[0]._id)"
      ></product-card>
      <product-card class="gtp-single-2 goldie-product-block" v-if="topProducts[1]"
        :from="typeOfProducts"
        :productName="topProducts[1].productName"
        :uniqueId="topProducts[1].uniqueId"
        :brandName="topProducts[1].brand.name"
        :fullSize="topProducts[1].fullSize"
        :_id="topProducts[1]._id"
        :isBookmark="topProducts[1].isBookmark"
        :size="topProducts[1].size"
        :notesFromTopPicks="'Fits just right'"
        :productImages="topProducts[1].productImages" @click="openProdDetailPopup(topProducts[1]._id)"
      ></product-card>
      <product-card class="gtp-single-3 goldie-product-block" v-if="topProducts[2]"
        :from="typeOfProducts"
        :productName="topProducts[2].productName"
        :uniqueId="topProducts[2].uniqueId"
        :brandName="topProducts[2].brand.name"
        :fullSize="topProducts[2].fullSize"
        :_id="topProducts[2]._id"
        :isBookmark="topProducts[2].isBookmark"
        :size="topProducts[2].size"
        :notesFromTopPicks="'Fits just right'"
        :productImages="topProducts[2].productImages" @click="openProdDetailPopup(topProducts[2]._id)"
      ></product-card>
      <product-card class="gtp-single-1 goldie-product-block" :class="{ 'gtpb4-row-2-1': topProducts.length == 4 }"
        v-if="topProducts[3]"
        :from="typeOfProducts"
        :productName="topProducts[3].productName"
        :uniqueId="topProducts[3].uniqueId"
        :brandName="topProducts[3].brand.name"
        :fullSize="topProducts[3].fullSize"
        :_id="topProducts[3]._id"
        :isBookmark="topProducts[3].isBookmark"
        :size="topProducts[3].size"
        :notesFromTopPicks="'Fits just right'"
        :productImages="topProducts[3].productImages" @click="openProdDetailPopup(topProducts[3]._id)"
      ></product-card>
      <product-card class="gtp-single-2 goldie-product-block" v-if="topProducts[4]"
        :from="typeOfProducts"
        :productName="topProducts[4].productName"
        :uniqueId="topProducts[4].uniqueId"
        :brandName="topProducts[4].brand.name"
        :fullSize="topProducts[4].fullSize"
        :_id="topProducts[4]._id"
        :isBookmark="topProducts[4].isBookmark"
        :size="topProducts[4].size"
        :notesFromTopPicks="'Fits just right'"
        :productImages="topProducts[4].productImages" @click="openProdDetailPopup(topProducts[4]._id)"
      ></product-card>
      <product-card class="gtp-single-3 goldie-product-block" v-if="topProducts[5]"
        :from="typeOfProducts"
        :productName="topProducts[5].productName"
        :uniqueId="topProducts[5].uniqueId"
        :brandName="topProducts[5].brand.name"
        :fullSize="topProducts[5].fullSize"
        :_id="topProducts[5]._id"
        :isBookmark="topProducts[5].isBookmark"
        :size="topProducts[5].size"
        :notesFromTopPicks="'Fits just right'"
        :productImages="topProducts[5].productImages" @click="openProdDetailPopup(topProducts[5]._id)"
      ></product-card>
      <div class="gtp-subscribe-block" :class="{ 'gtpb4-row-2-2': topProducts.length == 4 }" v-if="showRetailerBox()">
        <div class="gtp-subscribe-block-content">
          <img src="assets/images/goldies-fav-friend-icon.svg" alt="">
          <h3 class="fs-24 fw-500">Don't see a style on Goldie?</h3>
          <div class="gtp-submit-retailer-cta">
            <a href="javascript:void(0);" class="fg-btn-link submit-retailer-link">
              <span>Submit a Retailer</span>
              <svg width="17" height="17" viewBox="0 0 17 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                  d="M8.49219 0.411499C8.49219 1.25722 9.22553 2.52277 9.96689 3.58562C10.9215 4.95402 12.0609 6.14943 13.3682 7.06213C14.3476 7.74556 15.5368 8.40162 16.4922 8.40162M8.49219 16.4115C8.49219 15.5658 9.22553 14.3002 9.96689 13.2374C10.9215 11.8691 12.0609 10.6736 13.3682 9.76087C14.3476 9.07744 15.5368 8.42138 16.4922 8.42138M16.4922 8.4115H0.492188"
                  stroke="#1F5CD7" stroke-width="2"></path>
              </svg>
            </a>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import $ from "jquery";
  import axios from "axios";
  import ProductCard from "../../ui/ProductCard.vue";

  export default {
    props: ["topProducts"],
    components: {
      ProductCard
    },
    data () {
      return {
        typeOfProducts: 1
      }
    },
    methods: {
      openProdDetailPopup: async function (productId) {
        // alert(productId);
        // ASSUME THIS A PRODUCT
        // let product = this.products.find(product => {
        //   return product._id == productId
        // });

        // Call api for product detail
        let measurement = this.$store.getters['products/getMeasurementFromLocal'];
        if (measurement && measurement.waist && measurement.hip && measurement.thigh && measurement.inseam) {
          let getToken = this.$store.getters.getToken;
          let headers = {};
          if (getToken) {
            headers.Authorization = "Bearer " + getToken;
          }
          axios.post(`${process.env.VUE_APP_API_BASE_URL}/product/detail`, {
            productId: productId,
            waist: measurement.waist,
            hip: measurement.hip,
            thigh: measurement.thigh,
            inseam: measurement.inseam
          }, {
            headers
          })
            .then((product) => {
              $(document).on('click', '.goldie-product-block a:not(.gpb-wishlist)', function () {
                // change URL
                window.history.pushState({}, '', `${process.env.VUE_APP_BASE_URL}/product/detail/${product.data.data.detail.slug}`);
              })
              this.emitter.emit("product-detail-data", {
                product: product.data.data.detail,
                fitNotes: product.data.data.fitNotes
              });
            })
        }
        // $('.product-modal-block').fadeIn();
        // $('.product-modal-block .product-modal-content').scrollTop(0);
      },
      topPicksBlockClasses: function () {
        if (this.topProducts && this.topProducts.length && this.topProducts.length > 3) {
          if (this.topProducts.length == 4) {
            return ["goldies-top-picks-block-1", "goldies-top-picks-block-4"];
          }
          if (this.topProducts.length == 5) {
            return ["goldies-top-picks-block-2", "goldies-top-picks-block-5"];
          }
          if (this.topProducts.length == 6) {
            return ["goldies-top-picks-block-3", "goldies-top-picks-block-6"];
          }
        } else {
          return [`goldies-top-picks-block-${this.topProducts.length}`];
        }
      },
      showRetailerBox: function () {
        let topPicksProducts = this.topProducts;
        if (topPicksProducts && topPicksProducts.length) {
          if (topPicksProducts.length > 3) {
            if (topPicksProducts[5] === undefined) {
              return true;
            }
          } else {
            if (topPicksProducts[2] === undefined) {
              return true;
            }
          }
        } else {
          return false;
        }
      }
    },
    async mounted() {
      if($(window).width() < 767){
        $('.goldies-top-picks-block').slick({
          infinite: false,
          adaptiveHeight: true,
          arrows: false, // Disable arrows
          cssEase: "ease-in-out",
          slidesToShow: 1,
          dots: false,
        });
      }
    }
  }
</script>

<style></style>
