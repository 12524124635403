<template>
  <div class="measurements-form-overlay"></div>
  <the-measurement></the-measurement>
  <section class="fg-results-sec sec-nav-blur" v-if="!hideAndShowRetailerSection">
    <div class="container">
      <div class="row">
        <div class="col-12">
          <div class="fg-results-container d-flex flex-wrap w-100">
            <!-- <h1>Loading...</h1> -->
            <the-filters v-if="mainProducts.length || topPicksProducts.length"
              :allBrands="allBrands"
              :allStyles="allStyles"
            ></the-filters>
            <div class="fg-results-products-block" :class="{'fg-results-products-block-top-picks': !mainProducts.length}">
              <!-- Goldies Top Picks -->
              <top-picks v-if="topPicksProducts.length && !hideTopPicks"
                :topProducts="topPicksProducts"
              ></top-picks>
              <!-- <submit-retailer-box v-if="!topPicksProducts.length && !hideTopPicks"></submit-retailer-box> -->
              <submit-retailer-box v-if="showSubmitBox"></submit-retailer-box>
              <!-- Goldies Top Picks -->
              <!-- Other Picks -->
              <!-- <other-picks v-if="mainProducts.length || showForFilter"></other-picks> -->
              <other-picks></other-picks>
              <!-- Other Picks -->
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
  <section class="empty-results-sec sec-nav-blur" v-if="hideAndShowRetailerSection">
    <div class="container">
      <div class="row">
        <div class="col-12">
          <div class="gtp-subscribe-block">
            <div class="gtp-subscribe-block-content">
              <img src="../assets/images/goldies-fav-friend-icon.svg" alt="">
              <h3 class="fs-24 fw-500">Don't see a style on Goldie?</h3>
              <div class="gtp-submit-retailer-cta">
                <a href="javascript:void(0);" class="fg-btn-link submit-retailer-link">
                  <span>Submit a Retailer</span>
                  <svg width="17" height="17" viewBox="0 0 17 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path
                      d="M8.49219 0.411499C8.49219 1.25722 9.22553 2.52277 9.96689 3.58562C10.9215 4.95402 12.0609 6.14943 13.3682 7.06213C14.3476 7.74556 15.5368 8.40162 16.4922 8.40162M8.49219 16.4115C8.49219 15.5658 9.22553 14.3002 9.96689 13.2374C10.9215 11.8691 12.0609 10.6736 13.3682 9.76087C14.3476 9.07744 15.5368 8.42138 16.4922 8.42138M16.4922 8.4115H0.492188"
                      stroke="#1F5CD7" stroke-width="2"></path>
                  </svg>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
  <notified-style
    :notifiedTitle="notifiedStyleText"
  ></notified-style>
  <product-detail-popup
    :brandName="singleProduct.brandName"
    :productName="singleProduct.productName"
    :productImages="singleProduct.productImages"
    :fullSize="singleProduct.fullSize"
    :productUrl="singleProduct.productUrl"
    :productDesc="singleProduct.productDesc"
    :stretch="singleProduct.stretch"
    :waistNotes="singleProduct.waistNotes"
    :hipNotes="singleProduct.hipNotes"
    :thighNotes="singleProduct.thighNotes"
    :inseamNotes="singleProduct.inseamNotes"
    :riseInProduct="singleProduct.rise"
    :isBookmark="singleProduct.isBookmark"
    :id="singleProduct._id"
    :isBookmarkBtn="singleProduct.isBookmarkBtn"
    :relatedProducts="relatedProductsArr"
  ></product-detail-popup>
  <measurement-block></measurement-block>
  <submit-retailer-popup></submit-retailer-popup>
  <how-to-measure></how-to-measure>
</template>

<script>
import $ from "jquery";
import axios from "axios";
import { gsap, ScrollTrigger, TimelineMax } from "gsap/all";

import TheMeasurement from "../components/layout/measurement/TheMesurement.vue";
import TheFilters from "../components/layout/products/TheFilters.vue";
import TopPicks from "../components/layout/products/TopPicks.vue";
import OtherPicks from "../components/layout/products/OtherPicks.vue";
import NotifiedStyle from "../components/layout/subscription/NotifiedStyle.vue";
import MeasurementBlock from "../components/layout/measurement/MeasurementBlock.vue";
import SubmitRetailerPopup from "../components/layout/submit-retailers/SubmitRetailerPopup.vue";
import HowToMeasure from "../components/layout/how-to-measure/HowToMeasure.vue";

import SubmitRetailerBox from "../components/layout/products/SubmitRetailerBox.vue";

import ProductDetailPopup from "../components/layout/products/ProductDetailPopup.vue";

export default {
  components: {
    TheMeasurement,
    TheFilters,
    TopPicks,
    OtherPicks,
    NotifiedStyle,
    ProductDetailPopup,
    MeasurementBlock,
    SubmitRetailerPopup,
    SubmitRetailerBox,
    HowToMeasure
  },
  data () {
    return {
      singleProduct: {},
      topPicksProducts: [],
      mainProducts: [],
      allBrands: [],
      allStyles: [],
      hideTopPicks: false,
      hideAndShowRetailerSection: false,
      signupDisplayed: false,
      showSubmitBox: false,
      showForFilter: false,
      cmToInch: 0.393701,
      relatedProductsArr: [],
      notifiedStyleText: "Get Notified of New Styles"
    }
  },
  async mounted() {
    const isLoggedIn = this.$store.getters.isAuth;
    if (!isLoggedIn) {
      const currentDate = new Date();

      // stored expiryDate STr - first time its null
      const expiryDateStr = localStorage.getItem('signupExpiryDate');
      let expiryDate = null;

      if (expiryDateStr) {
        expiryDate = new Date(expiryDateStr);
      }

      if (!expiryDate || isNaN(expiryDate.getTime()) || currentDate > expiryDate) {
        setTimeout(this.showSignupPage, 15000); // 15 seconds
          $(document).on('click', '.popup-signup-close', function(){
            $('body').removeClass('noscroll');
            $('.popup-signup-login').css({'display':'none'});
            $('.signup-form-popup').hide();
            this.signupDisplayed = false;

            const currentDate = new Date();
            const newExpiryDate = new Date(currentDate.getTime() + 1 * 24 * 60 * 60 * 1000); // 1 days later
            localStorage.setItem('signupExpiryDate', newExpiryDate.toISOString());
        })
      }
    }

    // Jquery stuff
    $('body').removeClass('noscroll');
    document.title = "Find Jeans That Actually Fit | Goldie";
    const metaDescription = document.querySelector('meta[name="description"]');
    if (metaDescription) {
      metaDescription.setAttribute('content', "Find Jeans That Actually Fit | Goldie");
    } else {
      const newMetaDescription = document.createElement('meta');
      newMetaDescription.setAttribute('name', "Find Jeans That Actually Fit | Goldie");
      newMetaDescription.setAttribute('content', "Find Jeans That Actually Fit | Goldie");
      document.head.appendChild(newMetaDescription);
    }
    // Event Emit
    this.emitter.on("product-detail-data", (data) => {
      // this.singleProduct = data;
      this.singleProduct = {
        brandName: data.product.brand.name,
        productName: data.product.productName,
        productImages: data.product.productImages || [],
        fullSize: data.product.fullSize,
        productUrl: data.product.productUrl,
        productDesc: data.product.productDesc,
        rise: data.product.rise,
        stretch: data.product.stretch,
        waistNotes: data.fitNotes.waistFitNotes,
        hipNotes: data.fitNotes.hipFitNotes,
        thighNotes: data.fitNotes.thighFitNotes,
        inseamNotes: data.fitNotes.inseamFitNotes,
        isBookmark: data.product.isBookmark,
        isBookmarkBtn: data.product.isBookmark ? `<button class="fg-btn-primary fg-btn-primary-blue" @click="showNow(${data.product.productUrl})">
                <span>Shop Now</span>
                <svg width="25" height="24" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path
                    d="M12.3545 0C12.3545 1.26859 13.4545 3.16691 14.5665 4.76118C15.9985 6.81377 17.7075 8.6069 19.6685 9.97595C21.1375 11.0011 22.9215 11.9852 24.3545 11.9852M12.3545 24C12.3545 22.7314 13.4545 20.8331 14.5665 19.2388C15.9985 17.1863 17.7075 15.3931 19.6685 14.0241C21.1375 12.9989 22.9215 12.0148 24.3545 12.0148M24.3545 12H0.354492"
                    stroke="white" stroke-width="2" />
                </svg>
              </button>
              <a href="javascript:void(0);" id="${data.product._id}-heart-pdc" class="pdc-wishlist heart-pdc active-wishlist-pdc ${data.product._id}" @click="bookmarkFav(${data.product._id})">`
                :
              `<button class="fg-btn-primary fg-btn-primary-blue" @click="showNow(${data.product.productUrl})">
                <span>Shop Now</span>
                <svg width="25" height="24" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path
                    d="M12.3545 0C12.3545 1.26859 13.4545 3.16691 14.5665 4.76118C15.9985 6.81377 17.7075 8.6069 19.6685 9.97595C21.1375 11.0011 22.9215 11.9852 24.3545 11.9852M12.3545 24C12.3545 22.7314 13.4545 20.8331 14.5665 19.2388C15.9985 17.1863 17.7075 15.3931 19.6685 14.0241C21.1375 12.9989 22.9215 12.0148 24.3545 12.0148M24.3545 12H0.354492"
                    stroke="white" stroke-width="2" />
                </svg>
              </button><a href="javascript:void(0);" class="pdc-wishlist heart-pdc ${data.product._id}" id="${data.product._id}-heart-pdc" @click="bookmarkFav(${data.product._id})">`,
        _id: data.product._id
      };
      // console.log("this.singleProduct---------------");
      // console.log(this.singleProduct);
      // console.log(data);
    });

    // measurment popup
    $('.measurement-block-onscroll .measurement-btn').on('click',function(){
      $('.mb-os-form').fadeToggle(100);
      /* $('body').addClass('noscroll'); */
      if($('.measurements-form-overlay').is(':visible')){
        $('.measurements-form-overlay').hide();
      }else{
        $('.measurements-form-overlay').show();
      }
      if($('body').hasClass('noscroll')){
        $('body').removeClass('noscroll')
      }else{
        $('body').addClass('noscroll')
      }
    });

    /*Measurements form toggle onscroll*/
    $(window).bind('scroll', function() {
      var scrollsec1 = $(window).scrollTop();
      var elementOffsetscrollsec1 = $('.sec-nav-blur').offset().top;
      var currentElementOffsetscrollsec1 = (elementOffsetscrollsec1 - scrollsec1);
      if(currentElementOffsetscrollsec1 < 5){
        console.log('second section reached top of screen');
        $('.fg-navigation .top-nav .container').addClass('nav-bg-blur');
        $('.measurement-block-onscroll .measurement-btn').fadeIn();
      }else{
        console.log('second sectiion else block');
        $('.fg-navigation .top-nav .container').removeClass('nav-bg-blur');
        $('.measurement-block-onscroll .measurement-btn').fadeOut();
        $('.mb-os-form').hide();
      }
    });

    $(document).ready(function () {
      $('.how-to-measure').on('click',function(){
        $('.popup-measurement').fadeIn();
        $('body').addClass('noscroll');
      });
    })

    /*Filter by button toggle on mobile*/
    if($(window).width() < 992)	{
      $(window).bind('scroll', function() {

        var scrollsec1 = $(window).scrollTop();
        var elementOffsetscrollOtherMatches = $('.results-other-matches-block').offset().top;
        var currentElementOffsetOtherMatches = (elementOffsetscrollOtherMatches - scrollsec1);
        if(currentElementOffsetOtherMatches < 100){
          //console.log('other fit mathces reached top of screen');
          $('.btn.btn-filter').fadeIn();
        }else{
          $('.btn.btn-filter').fadeOut();
        }
      });
    }
    /*Filter by button toggle on mobile*/
    /*Navigation to hide on scroll*/
    const showAnim = gsap
      .from(".top-nav", {
        yPercent: -150,
        paused: true,
        duration: 0.3,
      })
      .progress(1);

      ScrollTrigger.create({
        trigger:".sec-nav-blur",
        start: "top 100",
        end: 999999,
        onUpdate: (self) => {
          self.direction === -1 ? showAnim.play() : showAnim.reverse();
        },
        // markers: true,
        // markers:{
        // 	startColor: '#f00',
        // 	endColor: '#9179CD',
        // 	fontSize: '2rem'
        // }
      });
    /*Navigation to hide on scroll*/
    /*Measurement button form toggle*/
    $('.measurement-btn').on('click', function () {
        $('.measurement-btn .arrow-icon').toggleClass('rotate');
      })

      // Define onStart function
      function onReverseCompleteFunction() {
        $('.measurement-btn .arrow-icon').removeClass('rotate');
      }
      function onStartFunction() {
        $('.measurement-btn .arrow-icon').addClass('rotate');
      }
      document.querySelector(".measurement-btn").addEventListener("click", animateIt);

      var tl = new TimelineMax();
      tl.to(".search-measurements-block", 0.1, {
        // y: 0,
        display: 'flex'
      })
        .to(".measurement-btn-block", 0.1, {
          marginTop: 0,
          onStart: onStartFunction,// Assign onStart callback function
          onReverseComplete: onReverseCompleteFunction // Assign onStart callback function
        }, "<");
      tl.reverse(-1)
      tl.reversed(true);

      function animateIt() {
        tl.reversed(!tl.reversed());
      }
      /*Measurement button form toggle*/
      $()
      /*Filter button toggle on mobile*/

      if ($(window).width() < 767) {
        $(document).on('click', '.btn-filter' ,function(){
          $('.fg-results-filter-block').css("transform","translateX(0)");
          $('.measurements-form-overlay').fadeIn(100);
          $('body').addClass('noscroll');
        });
        $(document).on('click', '.filter-block-close' ,function(){
          $('.fg-results-filter-block').css("transform","translateX(-100%)");
          $('.measurements-form-overlay').fadeOut(100);
          $('body').removeClass('noscroll');
        })
      }
      /*Filter button toggle on mobile*/
    // Event Emit
    this.emitter.on("top-pick-hide", (data) => {
      // console.log("top-pick-hide-----");
      // console.log(data);
      // console.log("data.filterApplied", data.filterApplied)
      // console.log("data.sortApplied", data.sortApplied)

      if (data.filterApplied || data.sortApplied) {
        this.hideTopPicks = true;
      } else {
        this.hideTopPicks = false;
      }
      // if (data.sortApplied) {
      //   this.hideTopPicks = true;
      // } else {
      //   this.hideTopPicks = data.hide;
      // }
    });

    let measurement = this.$store.getters['products/getMeasurementFromLocal'];
    // alert(JSON.stringify(measurement));
    if (measurement && measurement.waist && measurement.hip && measurement.thigh && measurement.inseam) {
      let getToken = this.$store.getters.getToken;
      let headers = {};
      if (getToken) {
        headers.Authorization = "Bearer " + getToken;
      }

      if (measurement.measurementType == "cms") {
        // alert("cms");
        measurement.waist = this.convertType(parseFloat(measurement.waist));
        measurement.hip = this.convertType(parseFloat(measurement.hip));
        measurement.thigh = this.convertType(parseFloat(measurement.thigh));
        measurement.inseam = this.convertType(parseFloat(measurement.inseam));
      }
      // const topPicksPromise = axios.post(`${process.env.VUE_APP_API_BASE_URL}/product/top-picks`, {
      //   waistInput: parseFloat(measurement.waist),
      //   hipInput: parseFloat(measurement.hip),
      //   thighInput: parseFloat(measurement.thigh),
      //   inseamInput: parseFloat(measurement.inseam),
      //   measurementType: measurement.measurementType
      // }, {
      //   headers
      // });

      const response = await axios.post(`${process.env.VUE_APP_API_BASE_URL}/product/plus/top/picks`, {
        waistInput: parseFloat(measurement.waist),
        hipInput: parseFloat(measurement.hip),
        thighInput: parseFloat(measurement.thigh),
        inseamInput: parseFloat(measurement.inseam),
        measurementType: measurement.measurementType
      }, {
        headers
      });

      // const response = {
      //   status: 200,
      //   data: {
      //     code: 200,
      //     data: {
      //       topPicks: [],
      //       products: [],
      //       brands: [],
      //       styles: []
      //     }
      //   }
      // };

      this.emitter.on("show-other-matches-by-filter", () => {
        this.showForFilter = true;
      })

      // const [topPicks, products] = await Promise.all([topPicksPromise, productsPromise])
      if (response && response.status == 200 && response.data.code == 200) {
        const topPicks = response.data.data.topPicks;
        const products = response.data.data.products;
        const brands = response.data.data.brands;
        const styles = response.data.data.styles;
        if (topPicks && topPicks.length) {
          this.topPicksProducts = topPicks;
          this.relatedProductsArr.push(...this.topPicksProducts);
        } else {
          this.showSubmitBox = true;
        }
        if (products && products.length) {
          this.mainProducts = products;
          this.relatedProductsArr.push(...this.mainProducts);
        }
        if (brands && brands.length) {
          this.allBrands = brands;
        }
        if (styles && styles.length) {
          this.allStyles = styles;
        }
      }

      // filter applied
      this.emitter.on("product-show-box", (data) => {
        // console.log("data.displayedProducts===============");
        // console.log(data.displayedProducts);
        if (data.displayedProducts && data.displayedProducts.length === 0) {
          this.showSubmitBox = true;
          } else {
          this.showSubmitBox = false;
        }
      })
    }
    /*Submit Retailer form toggle*/
    $(document).on('click', '.submit-retailer-link', function(){
      $('.popup-submit-retailer').css({'display':'flex'});
      $('body').addClass('noscroll');
    })
    $('.popup-signup-close').on('click',function(){
      $('.popup-signup-login').hide();
      $('.popup-measurement').hide();
      $('.popup-submit-retailer').hide();
      $('.popup-feedback').hide();
      $('body').removeClass('noscroll');
    })
  },

  methods: {
    showSignupPage () {
      $('body').addClass('noscroll');
      $('.popup-signup-login').css({ 'display': 'flex' });
      $('.signup-form-popup').show();
      this.signupDisplayed = true;
    },
    convertType: function (value) {
      let inchValue = (value * this.cmToInch).toFixed(2);
      if (Math.abs(inchValue - Math.round(inchValue)) < 0.01) {
        return inchValue;
      } else {
        return Math.round(inchValue);
      }
    },
  }
  // created() {

  //   // if (document.querySelector('script[src="/js/homeScreen/homeScript.js"]')) {
  //   //   var scriptTags = document.querySelectorAll('script[src="/js/homeScreen/homeScript.js"]');
  //   //   scriptTags.forEach(function(scriptTag) {
  //   //     scriptTag.parentNode.removeChild(scriptTag);
  //   //   });
  //   // }


  //   if (!document.querySelector('script[src="/js/productScreen/productScript.js"]')) {
  //     const scriptProduct = document.createElement('script');
  //     scriptProduct.src = '/js/productScreen/productScript.js'; // taking from public folder
  //     scriptProduct.async = true;
  //     document.head.appendChild(scriptProduct);
  //   }
  // },
}
</script>

<style></style>
